import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import accordionarrow from 'assets/images/arrow-accordin.svg';
// import accordionarrow from 'assets/images/arrow-accordin.svg';
import CheckGreen from 'assets/images/check-alert-green.svg';
import CheckRed from 'assets/images/check-alert-red.svg';
import eyeIcon from 'assets/images/eye.png';
import editIcon from 'assets/images/pencil.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import DatePicker from 'components/DatePicker';
import YTDBilledTableComp from 'components/Paysheet/YTDBilledTableComp';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { addNotesForm } from 'types';
import { alertOptions, roundOfData } from 'utils/Config';
import { MASTER_ENDPOINT, PAYSHEET, TIMESHEET } from 'utils/Constant';
import { addNotesSchema } from 'utils/ValidatorSchema';

const style = {
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '45%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
};

const carryOverStyle = {
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '45%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

const notesStyle = {
  container: {
    maxHeight: 400,
    overFlow: 'scroll',
  },
  createDate: {
    color: '#8F9CA1',
    fontFamily: 'Red Hat Display Medium',
    fontSize: '14px',
  },
  notesText: {
    color: '#292929',
    fontFamily: 'Red Hat Display Medium',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '17px',
    paddingTop: '4px',
  },
  userName: {
    color: '#292929',
    fontFamily: 'Red Hat Display Bold',
    fontSize: '14px',
    paddingRight: '5px',
  },
};

const style1 = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 486,
};

type PaysheetProps = {
  consultantId: string;
  from?: string;
  userInfo?: any;
};

const PaySheet = (props: PaysheetProps) => {
  const [open, setOpen] = React.useState<any>({
    id: '',
    visible: false,
  });
  const [ytdModal, openYtdModal] = useState<any>({
    id: '',
    notes: '',
    visible: false,
  });
  const [openAddLineItemModal, setOpenAddLineItemModal] = React.useState(false);
  const [addLineItemModalInput, setAddLineItemModalInput] = React.useState({
    amount: '',
    consultantUserId: props?.consultantId || '',
    note: '',
    paymentDate: '',
  });
  const handleCloseAddLineItemModal = () => {
    setAddLineItemModalInput({
      amount: '',
      consultantUserId: props?.consultantId || '',
      note: '',
      paymentDate: '',
    });

    setOpenAddLineItemModal(false);
  };

  const handleSubmitAddLineItem = async () => {
    if (!addLineItemModalInput?.paymentDate) {
      toast.error('Please add payment date', alertOptions);
      return;
    }

    if (!addLineItemModalInput?.amount) {
      toast.error('Please add amount', alertOptions);
      return;
    }

    const data = {
      ...addLineItemModalInput,
      amount: parseFloat(addLineItemModalInput?.amount),
    };

    try {
      const response = await addNewData(
        data,
        PAYSHEET.AchAdjustment,
        alertOptions,
        true,
      );

      if (response.id) {
        toast.success('Line item added successfully', alertOptions);
        getPayRollDetails(props?.consultantId, expanded);
        handleCloseAddLineItemModal();
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to add line item. Please try again.', alertOptions);
    }
  };
  const handleYtdClose = () => {
    openYtdModal({
      id: '',
      notes: '',
      visible: false,
    });
    reset();
  };
  const handleOpen = (id: string) => {
    setOpen({
      id: id,
      visible: true,
    });
    getTimeSheetData(id);
  };
  const handleClose = () => {
    setOpen({
      id: '',
      visible: false,
    });
  };
  const [openDeductions, setOpenDeductions] = React.useState<any>({
    data: [],
    id: '',
    total: 0,
    visible: false,
    year: '',
  });

  const [carryOverVisible, setCarryOverVisible] = React.useState(false);
  const [carryOverLoading, setCarryOverLoading] = React.useState(false);
  const [carryOverAmount, setCarryOverAmount] = React.useState('');
  const [carryOver, setCarryOver] = React.useState({
    amount: 0,
    visible: false,
  });

  const submitCarryOverAmount = () => {
    if (!carryOver.amount) {
      toast.error('Please add amount', alertOptions);
      return;
    }
    setCarryOverLoading(true);
    const data = {
      carryOverAmount: Number(carryOver?.amount),
      totalPaid: total ? total : 0,
      userId: props?.consultantId,
    };
    updateData(expanded, data, PAYSHEET.Payroll + '/paysheets/carryOver', true)
      .then(() => {
        setCarryOverLoading(false);
        setCarryOverVisible(false);
        handleCloseCarryOver();
        carryOverFunction(expanded);
        getPayRollDetails1(props?.consultantId);
      })
      .catch(() => {
        setCarryOverLoading(false);
      });
  };

  const handleOpenCarryOver = () => {
    setCarryOver({
      amount: 0,
      visible: true,
    });
  };

  const handleCloseCarryOver = () => {
    setCarryOver({
      amount: 0,
      visible: false,
    });
  };

  const [deductionArr, setDeductionArr] = React.useState([]);
  const [deductionLoading, setDeductionLoading] = React.useState(false);
  const saveDeduction = () => {
    let validateAll = true;
    deductionArr.map((item: any) => {
      if (!item.item || !item.date || !item.amount) {
        validateAll = false;
      }
    });
    if (validateAll) {
      addNewData(
        { outlays: deductionArr },
        PAYSHEET.Payroll + '/outlays',
        '',
        true,
      )
        .then((resp: any) => {
          setDeductionArr([]);
          handleDeductionpopup(openDeductions.year);
        })
        .catch((err: any) => {});
    } else {
      toast.error('Please add all fields', alertOptions);
    }
  };
  const handleDeductionpopup = (year: any) => {
    setDeductionLoading(true);
    getAllListData(
      PAYSHEET.Payroll +
        '/outlays?userId=' +
        props?.consultantId +
        '&year=' +
        year +
        '&type=deduction',
    )
      .then((response: any) => {
        let totalValue = 0;
        response?.data.map((item: any) => {
          totalValue += totalValue + item.amount ? parseFloat(item.amount) : 0;
        });
        setOpenDeductions({
          data: response?.data,
          id: '',
          total: totalValue,
          visible: true,
          year,
        });
        setDeductionLoading(false);
      })
      .catch((err) => {
        setOpenDeductions({
          data: [],
          id: '',
          total: 0,
          visible: true,
          year,
        });
        setDeductionLoading(false);
      });
  };

  const handleDeductionClose = () => {
    setOpenDeductions({
      data: [],
      id: '',
      total: 0,
      visible: false,
      year: '',
    });
  };
  const [timeSheetData, setTimeSheetData] = useState<any>({});
  const [timeSheetLoading, setTimeSheetLoading] = useState<boolean>(false);
  const getTimeSheetData = (id) => {
    setTimeSheetData({});
    setTimeSheetLoading(true);
    getAllListData(
      TIMESHEET.TimeSheets +
        '?userId=' +
        props?.consultantId +
        '&payPeriodId=' +
        id,
    )
      .then((response: any) => {
        setTimeSheetLoading(false);
        setTimeSheetData(response?.data?.[0]);
      })
      .catch((err) => {
        setTimeSheetLoading(false);
      });
  };

  const [total, setTotal] = useState<any>(0);
  const [payrollData, setPayrollData] = useState<any>([]);
  const [payrollYearData, setPayrollYearData] = useState<any>([]);
  const [loader1, setLoader1] = useState<boolean>(false);

  const [expanded, setExpanded] = React.useState('');
  const handleChange = (panel: string) => {
    setExpanded(panel !== expanded ? panel : '');
  };

  useEffect(() => {
    getPayRollYear();
    getPayRollDetails1(props?.consultantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.consultantId]);

  const [currentpayroll, setCurrentPayroll] = React.useState<any>({});
  const [ytdBilledData, setYtdBilledData] = React.useState<any>({});

  const getPayRollDetails1 = (userId: string) => {
    const url: any =
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${PAYSHEET.Payroll}?userId=${userId}`;
    getAllListData(url)
      .then((resp: any) => {
        setCurrentPayroll(resp?.data);
      })
      .catch((err: any) => {});
  };

  const getYtdBilled = (userId: string) => {
    setLoader1(true);
    const url: any =
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${PAYSHEET.YtdBilled}?userId=${userId}`;
    getAllListData(url)
      .then((resp: any) => {
        setYtdBilledData(resp?.data);
        setLoader1(false);
      })
      .catch((err: any) => {
        setLoader1(false);
      });
  };

  useEffect(() => {
    if (expanded) {
      getPayRollDetails(props?.consultantId, expanded);
      getYtdBilled(props?.consultantId);
    } else {
      setPayrollData([]);
      setTotal(0);
      setTotalExpenses(0);
      setExpensesList([]);
      setTotalDeduction(0);
      setIsHourlyUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, props?.consultantId]);

  const carryOverFunction = (yearId: string) => {
    getAllListData(
      PAYSHEET.Payroll +
        '/paysheets/carryOver?userId=' +
        props?.consultantId +
        '&payPeriodYearId=' +
        yearId,
    ).then((res: any) => {
      if (res?.data && Object.keys(res?.data).length) {
        setCarryOverAmount(res?.data?.amount);
      } else {
        setCarryOverVisible(true);
      }
    });
  };

  const getPayRollYear = () => {
    const url: any =
      MASTER_ENDPOINT.Payperiods + '?year=' + moment().format('yyyy');
    getAllListData(url)
      .then((resp: any) => {
        if (resp?.data && resp?.data?.length) {
          const fIndex = resp?.data.findIndex(
            (item: any) =>
              moment(item?.year?.toString()).format('YYYY') ===
              moment().format('YYYY'),
          );
          if (fIndex !== -1) {
            setExpanded(resp?.data?.[fIndex]?.['id']);
            if (props?.from === 'admin') {
              carryOverFunction(resp?.data?.[fIndex]?.['id']);
            }
          }
        }
        setPayrollYearData(resp?.data);
      })
      .catch((err: any) => {});
  };

  const [totalDeduction, setTotalDeduction] = React.useState(0);
  const [totalExpenses, setTotalExpenses] = React.useState(0);
  const [expensesList, setExpensesList] = React.useState([]);
  const [expensesOpen, setExpensesOpen] = React.useState({
    open: false,
    year: '',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isHourlyUser, setIsHourlyUser] = useState<boolean>(false);
  // const [payAvailableBuffer, setPayAvailableBuffer] = useState<any>({});
  const getPayRollDetails = (userId: string, yearId: string) => {
    setLoader(true);
    const url1: any = PAYSHEET.Payroll + '/expenses?userId=' + userId;
    getAllListData(url1)
      .then(async (resp: any) => {
        let expensesTotal: any = 0.0;
        await resp?.data.map((item: any) => {
          expensesTotal =
            (expensesTotal ? parseFloat(expensesTotal) : 0) +
            (item.payAmount ? parseFloat(item.payAmount) : 0);
        });
        setExpensesList(resp?.data);
        setTotalExpenses(expensesTotal);
      })
      .catch((err: any) => {});
    // setPayAvailableBuffer({});
    const url: any =
      PAYSHEET.Payroll +
      '/paysheets?userId=' +
      userId +
      '&payPeriodYearId=' +
      yearId;
    getAllListData(url)
      .then(async (resp: any) => {
        const datePastAObject = resp?.data.sort(function (a, b) {
          const c: any = new Date(a.payDate);
          const d: any = new Date(b.payDate);
          return c - d;
        });
        setPayrollData(datePastAObject);
        let overAllTotal = 0;
        let deductionTotal: any = 0.0;
        // const isBeforeFind = resp?.data.find((item: any) =>
        //   moment().isAfter(moment(item?.payDate)),
        // );
        // setPayAvailableBuffer(isBeforeFind);
        // let hourlyUser = false;
        if (resp?.data && resp?.data?.length) {
          const jCode = resp?.data?.[0]?.jobWcCode
            ? resp?.data?.[0]?.jobWcCode
            : '';
          if (jCode) {
            // hourlyUser =
            //   jCode.search('HOURLY') === 1 || jCode.search('SALARY') === 1
            //     ? true
            //     : false;
            setIsHourlyUser(
              jCode.search('HOURLY') === 1 || jCode.search('SALARY') === 1
                ? true
                : false,
            );
          }
        }
        await resp?.data.map((item: any) => {
          deductionTotal =
            (deductionTotal ? parseFloat(deductionTotal) : 0) +
            (item.deductionAmount ? parseFloat(item.deductionAmount) : 0);
          // if (hourlyUser) {
          //   overAllTotal = overAllTotal + (Number(item.paysheetAmount) || 0);
          // } else {
          //   if (item.id === 'a86f48d8-1db7-4f95-94ea-dad21447a58e') {
          //     overAllTotal = overAllTotal + (Number(item.adpTotalPaid) || 0);
          //   } else if (item.grossEarnings) {
          //     overAllTotal =
          //       overAllTotal +
          //       (Number(item.adpTotalPaid) || 0) +
          //       (Number(item.grossEarnings) || 0);
          //   }
          // }
          overAllTotal = overAllTotal + (Number(item.paysheetAmount) || 0);
        });
        setTotalDeduction(Number(deductionTotal.toFixed(2)));
        setTotal(resp?.totalPaid ?? 0);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const getTotalHours = (row) => {
    let totalHours = 0.0;
    if (row?.timesheetPeriods) {
      row?.timesheetPeriods.map((data) => {
        data.timesheets.map((timesheet) => {
          totalHours = Number(totalHours) + Number(timesheet.hours);
        });
        data.outSicks.map((outSick) => {
          totalHours = Number(totalHours) + Number(outSick.hours);
        });
      });
    }
    return Number(totalHours).toFixed(2);
  };

  const getTotalHoursSingleRow = (timesheetPeriod: any) => {
    if (timesheetPeriod && timesheetPeriod.timesheets) {
      let totalHours = 0.0;
      timesheetPeriod.timesheets.map((timesheet) => {
        totalHours = Number(totalHours) + Number(timesheet.hours);
      });
      timesheetPeriod.outSicks.map((outSick) => {
        totalHours = Number(totalHours) + Number(outSick.hours);
      });
      return Number(totalHours).toFixed(2);
    }
  };

  const [notesData, setNotesData] = React.useState<any>({
    note: '',
    open: false,
    row: {},
  });
  const handleNotesOpen = (row: any) => {
    setNotesData({
      ...notesData,
      open: true,
      row: row,
    });
  };

  const handleNotesClose = () => {
    setNotesData({
      note: '',
      open: false,
      row: {},
    });
    reset();
  };

  const {
    handleSubmit: handleAddNotesSubmit,
    control,
    formState,
    setValue,
    reset,
  } = useForm<addNotesForm>({
    defaultValues: {
      note: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addNotesSchema),
  });

  const [notesLoader, setNotesLoader] = React.useState<boolean>(false);
  const submitaddNotes = (data) => {
    setNotesLoader(true);
    const notesType =
      notesData.row.amountType === 'ACH Adjustment' ? 'ach' : 'paysheet';
    const bodyObject = {
      note: data.note,
      userId: props?.consultantId,
    };
    notesType === 'ach'
      ? (bodyObject['achAdjustmentId'] = notesData.row.achAdjustmentId)
      : (bodyObject['payPeriodId'] = notesData.row.payPeriodId);

    const url =
      notesType === 'ach'
        ? PAYSHEET.AchAdjustmentNote
        : PAYSHEET.Payroll + '/paysheets/notes';
    addNewData(bodyObject, url, {}, true)
      .then((resp: any) => {
        setNotesLoader(false);
        handleNotesClose();
        getPayRollDetails(props?.consultantId, expanded);
      })
      .catch((err: any) => {
        setNotesLoader(false);
      });
  };

  const submitAddYtdNotes = (data) => {
    setNotesLoader(true);

    if (ytdModal.notes.length) {
      updateData(
        ytdModal.id,
        { note: data.note },
        PAYSHEET.YtdBilledNotesAdd,
        true,
      )
        .then((resp: any) => {
          setNotesLoader(false);
          handleYtdClose();
          getYtdBilled(props?.consultantId);
        })
        .catch((err: any) => {
          setNotesLoader(false);
        });
    } else {
      const bodyObject = {
        note: data.note,
        pivotId: ytdModal.id,
        userId: props?.consultantId,
      };
      addNewData(bodyObject, PAYSHEET.YtdBilledNotesAdd, {}, true)
        .then((resp: any) => {
          setNotesLoader(false);
          handleYtdClose();
          getYtdBilled(props?.consultantId);
        })
        .catch((err: any) => {
          setNotesLoader(false);
        });
    }
  };

  const YtdController = useCallback(() => {
    return (
      <Controller
        control={control}
        defaultValue={''}
        name="note"
        render={({ field: { onChange, value, name, ref } }) => {
          return (
            <CustomInput
              className="common-textarea"
              id="outlined-multiline-static"
              isTextArea={true}
              minRows={3}
              type={'text'}
              name={name}
              placeHolder={'Add Notes'}
              value={value}
              ref={ref}
              onChange={(e: any) => {
                onChange(e);
              }}
            />
          );
        }}
      />
    );
  }, [control]);

  const YtdHourlyComp = ({
    payRate,
    totalBilledHours,
    adjustedApplicantHours,
    timesheetHours,
    grossSalary,
  }: any) => {
    return (
      <>
        <Grid
          container
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={4}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Pay Rate
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(payRate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={4}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Total Hours
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="paysheet-ytd-table-amount">
              {totalBilledHours}
            </Typography>
          </Grid>
        </Grid>
        {process.env.REACT_APP_ENV !== 'PRODUCTION' && (
          <Grid
            container
            sx={{
              marginLeft: '20px',
              width: 'calc(100% - 40px)',
            }}>
            <Grid item md={4}>
              <Typography
                className="paysheet-ytd-table-date"
                sx={{ textAlign: 'left !important' }}>
                Adjusted Hours
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className="paysheet-ytd-table-amount">
                {adjustedApplicantHours}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={4}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Total Submitted (Hours)
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="paysheet-ytd-table-amount">
              {timesheetHours}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Gross Salary
            </Typography>
          </Grid>
          <Grid item md={1} />
          <Grid item md={2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(grossSalary)}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const YtdMonthlyComp = ({
    AdminFees,
    totalDeduction,
    emptyField,
    employetax,
    grossSalary,
  }: any) => {
    return (
      <>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3} sx={{ paddingLeft: '0px !important' }}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Admin Fees
            </Typography>
          </Grid>
          <Grid item md={2} />
          <Grid item md={2.2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(AdminFees)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3} sx={{ paddingLeft: '0px !important' }}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Deductions
            </Typography>
          </Grid>
          <Grid item md={2} />
          <Grid item md={2.2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(totalDeduction)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3} />
          <Grid item md={2} />
          <Grid item md={2.2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(emptyField)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3} sx={{ paddingLeft: '0px !important' }}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Employer Taxes
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography className="paysheet-ytd-table-amount">
              10.00%
            </Typography>
          </Grid>
          <Grid item md={2.2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(employetax)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={3} sx={{ paddingLeft: '0px !important' }}>
            <Typography
              className="paysheet-ytd-table-date"
              sx={{ textAlign: 'left !important' }}>
              Gross Salary
            </Typography>
          </Grid>
          <Grid item md={2} />
          <Grid item md={2.2}>
            <Typography className="paysheet-ytd-table-amount">
              {roundOfData(grossSalary)}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const YTDBilledComp = ({ ytdBilledData, item }: any) => {
    let OverAllGrossSalary = 0;
    return (
      <>
        <Typography className="paysheet-ytd-billed-lable">
          YTD Billed
        </Typography>
        <Box className="paysheet-tables-ytd-overflow">
          {ytdBilledData.map((val: any, index: number) => {
            let grossSalary = 0;
            const totalHours =
              (val?.billRate - val?.volumeDiscountRate) * val?.totalBilledHours;
            const AdminFees = val?.totalBilledHours * val?.adminRate;
            const emptyField = totalHours - AdminFees;
            const employetax = emptyField * (10 / 100);
            if (val?.user.jobWcCode === '3P/I') {
              grossSalary = emptyField - employetax;
            } else if (val?.user.jobWcCode === '2HOURLY') {
              val?.pivot?.map((bill) => {
                grossSalary += Number(bill.payAmount);
              });
            }
            OverAllGrossSalary += Number(grossSalary);
            let totalBillHours = 0;
            val?.pivot.map((bill) => {
              totalBillHours += Number(bill.billHours);
            });
            let totalPayAmount = 0;
            val?.pivot.map((bill) => {
              totalPayAmount += Number(bill.payAmount);
            });
            return (
              <Box className="paysheet-ytd-box" key={index}>
                <Tooltip title={val?.project?.projectName}>
                  <Typography
                    className="paysheet-ytd-header"
                    sx={{ marginTop: '23px !important' }}>
                    {val?.project?.projectName}
                  </Typography>
                </Tooltip>
                <Typography className="paysheet-ytd-header">
                  {moment(val.startDate).format('MM/DD/YY') + ' - '}
                  {moment(val.endDate).format('MM/DD/YY')}
                </Typography>
                {!isHourlyUser ? (
                  <Box className="paysheet-ytd-header-2-outline">
                    <Typography className="paysheet-ytd-header-2">
                      Rate: <strong>{roundOfData(val?.billRate)}</strong>
                    </Typography>
                    <Typography className="paysheet-ytd-header-2">
                      Discount:{' '}
                      <strong>{roundOfData(val?.volumeDiscountRate)}</strong>
                    </Typography>
                    <Typography className="paysheet-ytd-header-2">
                      Admin Fee: <strong>{roundOfData(val?.adminRate)}</strong>
                    </Typography>
                  </Box>
                ) : null}
                <YTDBilledTableComp
                  val={val}
                  openYtdModal={openYtdModal}
                  setValue={setValue}
                />
                <Divider
                  sx={{
                    marginBottom: '6px',
                    marginLeft: '20px',
                    width: 'calc(100% - 40px)',
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginLeft: '20px',
                    width: 'calc(100% - 40px)',
                  }}>
                  <Grid item md={3} sx={{ paddingLeft: '0px !important' }}>
                    <Typography
                      className="paysheet-ytd-table-amount"
                      sx={{ textAlign: 'left !important' }}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography
                      className="paysheet-ytd-table-amount"
                      sx={{ textAlign: 'right !important' }}>
                      {totalBillHours}
                    </Typography>
                  </Grid>
                  <Grid item md={2.2}>
                    <Typography
                      className="paysheet-ytd-table-amount"
                      sx={{ textAlign: 'right !important' }}>
                      {roundOfData(totalPayAmount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    marginBottom: '6px',
                    marginLeft: '20px',
                    width: 'calc(100% - 40px)',
                  }}
                />
                {isHourlyUser ? (
                  <YtdHourlyComp
                    payRate={val?.payRate}
                    totalBilledHours={val?.totalBilledHours}
                    adjustedApplicantHours={val?.adjustedApplicantHours}
                    timesheetHours={val?.timesheetHours}
                    grossSalary={grossSalary}
                  />
                ) : (
                  <YtdMonthlyComp
                    AdminFees={AdminFees}
                    totalDeduction={totalDeduction}
                    emptyField={emptyField}
                    employetax={employetax}
                    grossSalary={grossSalary}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        <Grid
          container
          sx={{
            marginLeft: '20px',
            marginTop: '15px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={6}>
            <Typography className="overall-total-fn">
              Prior Year Balance Forward
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              className="overall-total-fn"
              sx={{
                marginRight: '21px !important',
                textAlign: 'right',
              }}>
              {roundOfData(
                currentpayroll?.actualCarryOverBuffer
                  ? currentpayroll?.actualCarryOverBuffer
                  : 0,
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginBottom: '12px',
            marginLeft: '20px',
            width: 'calc(100% - 40px)',
          }}>
          <Grid item md={6}>
            <Typography className="overall-total-sal">
              Total Gross Salary
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography
              className="overall-total-sal"
              sx={{
                marginRight: '21px !important',
                textAlign: 'right',
              }}>
              {roundOfData(
                Number(OverAllGrossSalary ? OverAllGrossSalary : 0) +
                  Number(
                    currentpayroll?.actualCarryOverBuffer
                      ? currentpayroll?.actualCarryOverBuffer
                      : 0,
                  ),
              )}
            </Typography>
          </Grid>
        </Grid>
        {props.userInfo?.eligibleForSickLeave === true ? (
          <Grid
            container
            sx={{
              marginBottom: '12px',
              marginLeft: '20px',
              marginTop: '-10px',
              width: 'calc(100% - 40px)',
            }}>
            <Grid item md={6}>
              <Typography className="overall-total-sal">
                Eligible Sick Hours
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                className="overall-total-sal"
                sx={{
                  marginRight: '21px !important',
                  textAlign: 'right',
                }}>
                {props.userInfo?.eligibleSickHours}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {moment(item.year, 'YYYY').format('YYYY') ===
          moment().format('YYYY') && (
          <Box className="paysheet-deduct-expense-box">
            <Box className="payment-deduct-expense-item">
              <Typography
                onClick={() => {
                  if (props.from === 'admin') {
                    handleDeductionpopup(item.year);
                  }
                }}
                sx={{
                  alignSelf: 'center',
                  color: '#979598',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  fontFamily: 'Red Hat Display SemiBold',
                  fontSize: '16px',
                  marginBottom: '11px',
                  marginLeft: '13px',
                  marginRight: '16px',
                  marginTop: '11px',
                }}
                className="deduction">
                Deductions:
                <strong>{roundOfData(totalDeduction)}</strong>
                {props.from === 'admin' ? (
                  <img
                    src={editIcon}
                    onClick={() => handleDeductionpopup(item.year)}
                    style={{
                      cursor: 'pointer',
                      height: '15px',
                      marginLeft: '5px',
                      width: '15px',
                    }}
                  />
                ) : (
                  <img
                    src={eyeIcon}
                    onClick={() => handleDeductionpopup(item.year)}
                    style={{
                      cursor: 'pointer',
                      height: '18px',
                      marginLeft: '5px',
                      width: '18px',
                    }}
                  />
                )}
              </Typography>
            </Box>
            <Box className="payment-deduct-expense-item">
              <Typography
                onClick={() =>
                  setExpensesOpen({
                    open: true,
                    year: item.year,
                  })
                }
                sx={{
                  alignSelf: 'center',
                  color: '#979598',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  fontFamily: 'Red Hat Display SemiBold',
                  fontSize: '16px',
                  marginBottom: '11px',
                  marginLeft: '13px',
                  marginRight: '16px',
                  marginTop: '11px',
                }}>
                Expenses:
                <strong>{roundOfData(totalExpenses)}</strong>
                <img
                  src={eyeIcon}
                  onClick={() =>
                    setExpensesOpen({
                      open: true,
                      year: item.year,
                    })
                  }
                  style={{
                    cursor: 'pointer',
                    height: '18px',
                    marginLeft: '5px',
                    width: '18px',
                  }}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <Typography component="div" className="container-block">
      <Typography
        sx={{ p: 2 }}
        component={'div'}
        className="common-accordin default-accordin">
        {payrollYearData.map((item: any, index: number) => {
          return (
            <Accordion key={index} expanded={expanded === item.id}>
              <AccordionSummary
                expandIcon={<img src={accordionarrow} />}
                aria-controls="panel1a-content"
                onClick={() => handleChange(item.id)}
                id="panel1a-header">
                <Typography
                  sx={{ width: '100% !important' }}
                  component={'div'}
                  className="accordin-details">
                  <Typography component={'p'}>
                    <strong>{item.year}</strong>
                  </Typography>
                  {carryOverAmount &&
                  expanded &&
                  item.year === Number(moment().format('YYYY')) &&
                  props?.from === 'admin' &&
                  props?.userInfo?.consultantStatus === 'offproject' ? (
                    <Typography
                      component={'div'}
                      sx={{
                        fontFamily: 'Red Hat Display SemiBold',
                        fontSize: '15px',
                        fontWeight: 'bold',
                      }}>
                      Carry Over Buffer Amount: {roundOfData(carryOverAmount)}
                    </Typography>
                  ) : (
                    ''
                  )}
                  {carryOverVisible &&
                  expanded &&
                  item.year === Number(moment().format('YYYY')) &&
                  props?.from === 'admin' &&
                  props?.userInfo?.consultantStatus === 'offproject' ? (
                    <Button
                      onClick={(event: any) => {
                        event.stopPropagation();
                        handleOpenCarryOver();
                      }}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#BF0000',
                          color: '#FFFFFF',
                        },
                        backgroundColor: '#BF0000',
                        border: '1px solid #E0E0E0',
                        color: '#FFFFFF',
                        fontFamily: 'Red Hat Display Bold',
                        height: '40px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        textTransform: 'capitalize',
                      }}>
                      Carry Over Buffer Amount
                    </Button>
                  ) : (
                    ''
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  variant="contained"
                  onClick={() => setOpenAddLineItemModal(true)}
                  style={{
                    backgroundColor: '#BF0000',
                    color: '#FFFFFF',
                    fontFamily: 'Red Hat Display Bold',
                    height: '40px',
                    marginBottom: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textTransform: 'capitalize',
                    width: 'auto',
                  }}>
                  Add Line Item
                </Button>
                {loader ? (
                  <Typography
                    component={'div'}
                    sx={{
                      justifyContent: 'center',
                      paddingBottom: '15px',
                      paddingTop: '5px',
                      textAlign: 'center',
                    }}>
                    <CircularProgress sx={{ color: '#340000' }} />
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={6}
                      sm={12}
                      xs={12}
                      className="paysheet-h-overflow"
                      sx={{
                        paddingLeft: '0px !important',
                      }}>
                      <>
                        {loader1 ? (
                          <Typography
                            component={'div'}
                            sx={{
                              background: 'rgba(55, 55, 55, 0.3)',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              paddingBottom: '15px',
                              paddingTop: '5px',
                              position: 'fixed',
                              width: '37%',
                            }}
                            className="paymentbox">
                            <CircularProgress
                              sx={{ alignSelf: 'center', color: '#340000' }}
                            />
                          </Typography>
                        ) : null}
                        {ytdBilledData.length ? (
                          <YTDBilledComp
                            ytdBilledData={ytdBilledData}
                            item={item}
                          />
                        ) : (
                          <div>No records</div>
                        )}
                      </>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={12}
                      xs={12}
                      className="paysheet-h-overflow">
                      <TableContainer className="common-table paysheet-table paysheet-tables-overflow">
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Pay Date:</TableCell>
                              {/* <TableCell
                                align="left"
                                sx={{ minWidth: '150px' }}>
                                Pay Period:
                              </TableCell> */}
                              {/* <TableCell align="left">Hours:</TableCell> */}
                              <TableCell align="left">Amount:</TableCell>
                              {/* <TableCell align="left">Sick Leave:</TableCell> */}
                              <TableCell align="left">Notes:</TableCell>
                              <TableCell align="left" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {payrollData.length ? (
                              <>
                                {payrollData.map((row: any, index: number) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}>
                                      <TableCell component="th" scope="row">
                                        {row.amountType === 'ACH Adjustment' ? (
                                          <div
                                            style={{
                                              margin: '0.2em',
                                            }}>
                                            <span
                                              style={{
                                                backgroundColor: '#FF0000',
                                                border: '1px solid #FF0000',
                                                borderRadius: '5px',
                                                fontSize: '11px',
                                                fontWeight: 'bold',
                                                padding: '0.2em',
                                              }}>
                                              <strong
                                                style={{
                                                  color: '#ffffff',
                                                }}>
                                                ACH Adj.
                                              </strong>
                                            </span>
                                          </div>
                                        ) : null}
                                        {moment(row.payDate)
                                          .utc()
                                          .format('MM/DD/YYYY')}
                                      </TableCell>
                                      {/* <TableCell align="left">
                                        {moment(row.startDate).format('MM/DD')}-
                                        {moment(row.endDate).format('MM/DD')}
                                      </TableCell> */}
                                      {/* <TableCell align="left">
                                        {row.timesheetHours}
                                      </TableCell> */}
                                      <TableCell
                                        align="left"
                                        sx={{ paddingLeft: '8px !important' }}>
                                        {/* <strong style={{ color: '#292929' }}> */}
                                        <Typography className="paysheet-tdbles-left-amount-lbl">
                                          {roundOfData(row.amount)}
                                        </Typography>
                                        {/* </strong> */}
                                      </TableCell>

                                      {/* <TableCell align="left">
                                        {row.currentHours3Amount2}
                                      </TableCell> */}
                                      <TableCell align="left" width={'10%'}>
                                        {row.notes && row?.notes?.length ? (
                                          <Box className="PaysheetNotesLayout">
                                            {/* <Typography
                                              noWrap
                                              className="PaysheetNotesText">
                                              {row?.notes[0]?.note}
                                            </Typography> */}
                                            <Button
                                              variant="text"
                                              onClick={(e) => {
                                                handleNotesOpen(row);
                                              }}
                                              sx={{
                                                color: '#005ebf',
                                                fontFamily:
                                                  'Red Hat Display Bold !important',
                                                fontSize: '14px !important',
                                                margin: '0px !important',
                                                minWidth: '0px !important',
                                                padding: '0px !important',
                                                textDecoration:
                                                  'underline !important',
                                                textTransform: 'capitalize',
                                              }}>
                                              View
                                            </Button>
                                          </Box>
                                        ) : (
                                          props.from === 'admin' && (
                                            <AddIcon
                                              className="pointer"
                                              onClick={(e) => {
                                                handleNotesOpen(row);
                                              }}
                                              sx={{
                                                color: '#005ebf',
                                              }}
                                            />
                                          )
                                        )}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        sx={{ width: '150px' }}
                                        onClick={() => handleOpen(row?.id)}>
                                        <a
                                          href="javascript:void(0);"
                                          className="showView">
                                          View Details
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell
                                  colSpan={6}
                                  sx={{
                                    color: '#241a2e',
                                    fontFamily: 'Red Hat Display SemiBold',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                  }}>
                                  No Records Found
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="paysheet-total-amt-box">
                        <Typography className="paysheet-total-fn">
                          TOTAL Paid
                        </Typography>
                        <Typography className="paysheet-total-val-fn">
                          {roundOfData(total)}
                        </Typography>
                      </Box>
                      {moment(item.year, 'YYYY').format('YYYY') ===
                        moment().format('YYYY') && (
                        <>
                          <Box className="paysheet-remain-fn">
                            <Typography className="paysheet-total-label">
                              Buffer Pending:
                            </Typography>
                            <Typography className="paysheet-total-value">
                              {isHourlyUser
                                ? roundOfData(
                                    (total ? total : 0) - (total ? total : 0),
                                  )
                                : roundOfData(
                                    currentpayroll?.currentBuffer
                                      ? currentpayroll?.currentBuffer
                                      : 0,
                                  )}
                            </Typography>
                          </Box>
                          {!isHourlyUser && (
                            <Box className="paysheet-remain-fn">
                              <Typography className="paysheet-total-label">
                                Hours Not Paid by Client:
                              </Typography>
                              <Typography className="paysheet-total-value">
                                {currentpayroll?.totalUnpaidHours
                                  ? currentpayroll?.totalUnpaidHours
                                  : 0}
                              </Typography>
                            </Box>
                          )}
                          {!isHourlyUser && (
                            <Box className="paysheet-remain-fn">
                              <Typography className="paysheet-total-label">
                                Available to Pay:
                              </Typography>
                              <Typography className="paysheet-total-value">
                                {roundOfData(
                                  currentpayroll?.bufferDue
                                    ? currentpayroll?.bufferDue
                                    : 0,
                                )}
                              </Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Typography>
      <Modal
        open={notesData.open}
        onClose={handleNotesClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1} className="model-widget">
          <Typography id="modal-modal-title" component="h2">
            <>{console.log(notesData.row)}</>
            {notesData.row.amountType === 'ACH Adjustment'
              ? 'ACH ADJUSTMENT NOTES'
              : 'PAYSHEET NOTES'}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {notesData?.row?.notes && notesData?.row?.notes?.length ? (
              <Typography
                component={'div'}
                sx={{
                  maxHeight: '40vh',
                  overflowY:
                    notesData?.row?.notes.length >= 3 ? 'scroll' : 'hidden',
                  width: '100%',
                }}>
                {notesData?.row?.notes.map((note, index) => (
                  <React.Fragment key={index}>
                    <Typography sx={{ paddingTop: '20px' }} />
                    <Typography component={'div'} sx={{ display: 'flex' }}>
                      <Typography component={'div'} sx={notesStyle.userName}>
                        {note.firstName} {note.lastName}
                      </Typography>
                      <Typography component={'div'} sx={notesStyle.createDate}>
                        {moment(note?.createdAt).format('MM/DD/YYYY')}
                      </Typography>
                    </Typography>
                    <Typography component={'div'} sx={notesStyle.notesText}>
                      {note?.note}
                    </Typography>
                    <Typography sx={{ paddingTop: '20px' }} />
                    <Divider />
                  </React.Fragment>
                ))}
              </Typography>
            ) : (
              <Typography />
            )}
            {props?.from === 'admin' && (
              <form
                onSubmit={handleAddNotesSubmit(submitaddNotes)}
                id={'AddNotes'}>
                <Typography component={'div'}>
                  <label>Enter notes</label>
                  <Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="note"
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <CustomInput
                            className="common-textarea"
                            id="outlined-multiline-static"
                            isTextArea={true}
                            minRows={3}
                            type={'text'}
                            name={name}
                            placeHolder={'Add Notes'}
                            value={value}
                            ref={ref}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                    {formState.errors?.note?.message && (
                      <Typography className="errorText">
                        {formState.errors?.note?.message}
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </form>
            )}
          </Typography>
          <Typography sx={{ paddingTop: '15px' }} />
          {props?.from === 'admin' && <Divider />}
          <Typography sx={{ paddingTop: '15px' }} />
          <Typography className="model-btn" component={'div'}>
            <Button onClick={handleNotesClose} variant="text">
              Cancel
            </Button>
            {props?.from === 'admin' && (
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Save Notes'}
                type="submit"
                form="AddNotes"
                loading={notesLoader}
              />
            )}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open.visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          {timeSheetLoading ? (
            <Typography
              component={'div'}
              sx={{
                justifyContent: 'center',
                paddingBottom: '15px',
                paddingTop: '5px',
                textAlign: 'center',
              }}>
              <CircularProgress sx={{ color: '#340000' }} />
            </Typography>
          ) : (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container className="paysheet-title">
                <Grid item xs={6}>
                  <Typography>
                    {moment(timeSheetData?.periodFrom).format('MMMM')}{' '}
                    <strong>
                      {moment(timeSheetData?.periodFrom).format('DD')} -{' '}
                      {moment(timeSheetData?.periodTo).format('DD')}
                    </strong>
                  </Typography>
                  {/* <Typography style={{ fontSize: '13px' }}>
                    Pay Period: <strong>1</strong>
                  </Typography> */}
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Typography>
                    Approved Hours:{' '}
                    <strong>{getTotalHours(timeSheetData)}</strong>
                  </Typography>
                  <Typography
                    style={{ fontSize: '14px' }}
                    onClick={handleClose}>
                    <a href="javascript:void(0);">Close</a>
                  </Typography>
                </Grid>
              </Grid>
              {timeSheetData?.timesheetPeriods &&
                timeSheetData?.timesheetPeriods.map(
                  (item: any, index: number) => {
                    return (
                      <Typography
                        component={'div'}
                        className="timesheet-table"
                        key={index}>
                        <Box
                          sx={{
                            borderRadius: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            m: 0,
                            p: 0,
                          }}>
                          <Typography>
                            {moment(item.periodFrom).format('MMM')}{' '}
                            <strong>
                              {moment(item.periodFrom).format('DD')} -{' '}
                              {moment(item.periodTo).format('DD')}
                            </strong>
                          </Typography>
                          <Typography style={{ paddingRight: '20px' }}>
                            Total:{' '}
                            <strong>{getTotalHoursSingleRow(item)}</strong>
                          </Typography>
                        </Box>
                        <TableContainer component={'div'}>
                          <Table
                            sx={{ Width: '100%' }}
                            aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Project Worked On:</TableCell>
                                <TableCell align="left">Paid:</TableCell>
                                <TableCell align="left">Hours:</TableCell>
                                <TableCell align="left">Adj. Hours:</TableCell>
                                {/* <TableCell>Timesheet:</TableCell> */}
                                <TableCell align="left">
                                  Current Rate:
                                </TableCell>
                                <TableCell align="left">Adj. Amount:</TableCell>
                                <TableCell align="left">Discount:</TableCell>
                                <TableCell align="left">Adj. Rate</TableCell>
                                <TableCell align="left">Admin Fee:</TableCell>
                                {/* <TableCell align="left">Notes:</TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.timesheets &&
                                item?.timesheets.map((item1: any) => {
                                  return (
                                    <TableRow
                                      className="active-blue"
                                      key={item1.id}>
                                      <TableCell component="th" scope="row">
                                        {item1?.project?.project}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.paid ? (
                                          <img src={CheckGreen} />
                                        ) : (
                                          <img src={CheckRed} />
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.hours}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.adjustmentHours}
                                      </TableCell>
                                      {/* <TableCell align="left">
                                  <a href="javascript:void(0);">View</a>
                                </TableCell> */}
                                      <TableCell align="left">
                                        {item1?.currentRate}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.adjustmentAmount}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.discount}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.adjustmentRate}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item1?.adminFee}
                                      </TableCell>
                                      {/* <TableCell align="left">
                                  <a href="javascript:void(0);">View</a>
                                </TableCell> */}
                                    </TableRow>
                                  );
                                })}
                              {item?.outSicks &&
                                item?.outSicks.map((item1: any) => {
                                  return (
                                    <TableRow
                                      className="active-blue"
                                      key={item1.id}>
                                      <TableCell component="th" scope="row">
                                        {'Out Sick'}
                                      </TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      <TableCell align="left">
                                        {item1?.hours}
                                      </TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      {/* <TableCell align="left">
                                  <a href="javascript:void(0);">View</a>
                                </TableCell> */}
                                      <TableCell align="left">{'-'}</TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      <TableCell align="left">{'-'}</TableCell>
                                      {/* <TableCell align="left">
                                  <a href="javascript:void(0);">View</a>
                                </TableCell> */}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Typography>
                    );
                  },
                )}
            </Typography>
          )}
        </Box>
      </Modal>

      {/* deductions popup */}
      <Modal
        open={openDeductions.visible}
        onClose={handleDeductionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          style={{ maxHeight: '500px', width: '900px' }}
          className="deduction-box">
          {deductionLoading ? (
            <Typography
              component={'div'}
              sx={{
                justifyContent: 'center',
                paddingBottom: '15px',
                paddingTop: '5px',
                textAlign: 'center',
              }}>
              <CircularProgress sx={{ color: '#340000' }} />
            </Typography>
          ) : (
            <Typography id="modal-modal-description">
              <Grid container className="paysheet-title">
                <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                  <Typography
                    sx={{ width: '100% !important' }}
                    component={'div'}
                    className="accordin-details">
                    <Typography component={'p'}>
                      {openDeductions.year} <strong>Deductions</strong>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={handleDeductionClose}
                    sx={{
                      border: '1px solid #E0E0E0',
                      color: '#292929',
                      fontFamily: 'Red Hat Display Bold',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      textTransform: 'capitalize',
                    }}>
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  {deductionArr.length ? (
                    <Button
                      onClick={saveDeduction}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#BF0000',
                          color: '#FFFFFF',
                        },
                        backgroundColor: '#BF0000',
                        border: '1px solid #E0E0E0',
                        color: '#FFFFFF',
                        fontFamily: 'Red Hat Display Bold',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        textTransform: 'capitalize',
                      }}>
                      Save
                    </Button>
                  ) : (
                    <Typography />
                  )}
                </Grid>
              </Grid>
              <TableContainer
                component={'div'}
                className="common-table paysheet-table"
                sx={{
                  '& .MuiTableCell-body': {
                    border: '1px solid #f5f5f5',
                  },
                  '& .MuiTableCell-head': {
                    background: '#DCE6EC',
                    border: '1px solid #f5f5f5',
                    padding: '10px',
                  },
                  '&::-webkit-scrollbar': {
                    height: '8px',
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#cfcfcf',
                    borderRadius: 2,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: '#fffff',
                    borderRadius: 2,
                  },
                  // maxHeight: '70vh',
                }}>
                <Table aria-label="simple table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Item</TableCell> */}
                      <TableCell align="left">Item</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      {/* <TableCell align="left" /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {openDeductions.data.map((row: any, index: number) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}>
                          <TableCell component="th" scope="row">
                            {row?.item}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(row.date).format('MM/DD/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            {roundOfData(row.amount ? row.amount : 0)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {deductionArr.map((item: any, index: number) => {
                      return (
                        <TableRow
                          key={openDeductions.data.length + index + 1}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}>
                          <TableCell component="th" scope="row">
                            <TextField
                              fullWidth
                              sx={{
                                '& input': {
                                  border: '0px solid white !important',
                                },
                              }}
                              value={item.item}
                              onChange={(e: any) => {
                                deductionArr[index].item = e.target.value;
                                setDeductionArr([...deductionArr]);
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <DatePicker
                              placeHolder="Select"
                              value={item.date}
                              onChange={(e: any) => {
                                deductionArr[index].date = e;
                                setDeductionArr([...deductionArr]);
                              }}
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ display: 'flex' }}>
                            <CurrencyInput
                              id="input-example"
                              prefix={'$'}
                              placeholder={''}
                              className={'width-100'}
                              defaultValue={item.amount}
                              style={{
                                border: '0px solid white !important',
                              }}
                              allowDecimals={true}
                              onValueChange={(e: any) => {
                                deductionArr[index].amount = e;
                                setDeductionArr([...deductionArr]);
                              }}
                            />
                            {/* <Typography
                              component={'div'}
                              onClick={() => {
                                deductionArr.splice(index, 1);
                                setDeductionArr([...deductionArr]);
                              }}
                              sx={{
                                alignSelf: 'center',
                                cursor: 'pointer',
                                paddingLeft: '5px',
                              }}>
                              <img src={arrowClose} />
                            </Typography> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        sx={{
                          border: '0px solid #f5f5f5 !important',
                        }}>
                        <Typography component={'div'} sx={{ display: 'flex' }}>
                          {props.from === 'admin' && (
                            <Typography
                              onClick={() => {
                                deductionArr.push({
                                  amount: '',
                                  date: '',
                                  item: '',
                                  type: 'deduction',
                                  userId: props?.consultantId,
                                });
                                setDeductionArr([...deductionArr]);
                              }}
                              component={'div'}
                              sx={{
                                color: '#4386F1',
                                cursor: 'pointer',
                                fontFamily: 'Red Hat Display Bold',
                                fontSize: '13px',
                                textDecoration: 'underline',
                              }}>
                              Add Deduction
                            </Typography>
                          )}
                          <Typography
                            component="div"
                            sx={{
                              color: '#292929',
                              fontFamily: 'Red Hat Display Regular',
                              fontWeight: '600',
                              paddingLeft: '20px',
                            }}>
                            Total:
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{ border: '0px solid #f5f5f5 !important' }}>
                        <Typography
                          component="div"
                          justifyContent={'flex-end'}
                          sx={{
                            color: '#292929',
                            fontFamily: 'Red Hat Display Regular',
                            fontWeight: 'bold',
                          }}>
                          {roundOfData(openDeductions?.total)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal
        open={expensesOpen.open}
        onClose={() =>
          setExpensesOpen({
            open: false,
            year: '',
          })
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          style={{ maxHeight: '500px', width: '900px' }}
          className="deduction-box">
          <Typography id="modal-modal-description">
            <Grid container className="paysheet-title">
              <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                <Typography
                  sx={{ width: '100% !important' }}
                  component={'div'}
                  className="accordin-details">
                  <Typography component={'p'}>
                    {expensesOpen.year} <strong>Expenses</strong>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() =>
                    setExpensesOpen({
                      open: false,
                      year: '',
                    })
                  }
                  sx={{
                    border: '1px solid #E0E0E0',
                    color: '#292929',
                    fontFamily: 'Red Hat Display Bold',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    textTransform: 'capitalize',
                  }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
            <TableContainer
              component={'div'}
              className="common-table paysheet-table"
              sx={{
                '& .MuiTableCell-body': {
                  border: '1px solid #f5f5f5',
                },
                '& .MuiTableCell-head': {
                  background: '#DCE6EC',
                  border: '1px solid #f5f5f5',
                  padding: '10px',
                },
                '&::-webkit-scrollbar': {
                  height: '8px',
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#cfcfcf',
                  borderRadius: 2,
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#fffff',
                  borderRadius: 2,
                },
                // maxHeight: '70vh',
              }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Item</TableCell> */}
                    <TableCell align="left">Customer Name</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    {/* <TableCell align="left" /> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expensesList.length ? (
                    expensesList.map((row: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row?.customerName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(row.periodStartDate).format('MM/DD/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            {roundOfData(row.payAmount ? row.payAmount : 0)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        sx={{
                          color: '#241a2e',
                          fontFamily: 'Red Hat Display SemiBold',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}>
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{ border: '0px solid #f5f5f5 !important' }}>
                      <Typography
                        component="div"
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular',
                          fontWeight: '600',
                        }}>
                        Total:
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: '0px solid #f5f5f5 !important' }}>
                      <Typography
                        component="div"
                        justifyContent={'flex-end'}
                        sx={{
                          color: '#292929',
                          fontFamily: 'Red Hat Display Regular',
                          fontWeight: 'bold',
                        }}>
                        {roundOfData(totalExpenses)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={carryOver.visible}
        onClose={handleCloseCarryOver}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={carryOverStyle} className="model-widget">
          <Typography id="modal-modal-title" component="h2">
            Carry Over Amount
          </Typography>
          <Grid container alignItems="center" sx={{ paddingTop: '20px' }}>
            <Grid sm={12}>
              <CurrencyInput
                id="input-example"
                prefix={'$'}
                placeholder={''}
                className={'width-100'}
                defaultValue={carryOver.amount}
                value={carryOver.amount}
                allowDecimals={true}
                onValueChange={(e: any) =>
                  setCarryOver({
                    ...carryOver,
                    amount: e,
                  })
                }
              />
            </Grid>
          </Grid>
          <Typography component={'div'} sx={{ paddingTop: '20px' }} />
          <Divider />
          <Typography component={'div'} sx={{ paddingTop: '20px' }} />
          <Typography className="model-btn" component={'div'}>
            <Button variant="text" onClick={handleCloseCarryOver}>
              Cancel
            </Button>
            <CustomButton
              variant="contained"
              isStyle={true}
              name={'Submit'}
              form={'changePassword'}
              loading={carryOverLoading}
              onPress={submitCarryOverAmount}
            />
          </Typography>
        </Box>
      </Modal>

      {/* YTD Pop-up */}
      <Modal
        open={ytdModal.visible}
        onClose={handleYtdClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1} className="model-widget">
          <Typography component="h2">YTD BILL NOTES</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props?.from === 'admin' && (
              <form
                onSubmit={handleAddNotesSubmit(submitAddYtdNotes)}
                id={'AddNotes'}>
                <Typography component={'div'}>
                  <label>Enter notes</label>
                  <YtdController />
                  <Typography>
                    {formState.errors?.note?.message && (
                      <Typography className="errorText">
                        {formState.errors?.note?.message}
                      </Typography>
                    )}
                  </Typography>
                </Typography>
              </form>
            )}
          </Typography>
          <Typography sx={{ paddingTop: '15px' }} />
          {props?.from === 'admin' && <Divider />}
          <Typography sx={{ paddingTop: '15px' }} />
          <Typography className="model-btn" component={'div'}>
            <Button onClick={handleYtdClose} variant="text">
              Cancel
            </Button>
            {props?.from === 'admin' && (
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Save Notes'}
                type="submit"
                form="AddNotes"
                loading={notesLoader}
              />
            )}
          </Typography>
        </Box>
      </Modal>

      {/* Add Line Item Pop-up */}
      <Modal
        open={openAddLineItemModal}
        onClose={handleCloseAddLineItemModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style1} className="model-widget">
          <Typography component="h2">Add Line Item</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props?.from === 'admin' && (
              <form onSubmit={(e) => console.log(e)} id={'AddLineItem'}>
                <Typography component={'div'}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <label
                      htmlFor="line-item-payment-date"
                      style={{ fontSize: '16px' }}>
                      Payment Date{' '}
                      <span
                        style={{
                          color: 'red',
                          fontSize: '14px',
                        }}>
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      id="line-item-payment-date"
                      placeholder="Enter date"
                      onChange={(e) =>
                        setAddLineItemModalInput((prev: any) => ({
                          ...prev,
                          paymentDate: new Date(e.target.valueAsDate)
                            .toISOString()
                            .split('T')[0],
                        }))
                      }
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '0.5em',
                    }}>
                    <label
                      htmlFor="line-item-amount"
                      style={{ fontSize: '16px' }}>
                      Amount{' '}
                      <span
                        style={{
                          color: 'red',
                          fontSize: '14px',
                        }}>
                        *
                      </span>
                    </label>
                    <input
                      type="number"
                      id="line-item-amount"
                      placeholder="0.00"
                      onChange={(e) =>
                        setAddLineItemModalInput((prev: any) => ({
                          ...prev,
                          amount: Number(e.target.value).toFixed(2),
                        }))
                      }
                    />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '1em',
                    }}>
                    <textarea
                      id="line-item-notes"
                      placeholder="Enter notes"
                      onChange={(e) =>
                        setAddLineItemModalInput((prev: any) => ({
                          ...prev,
                          notes: e.target.value,
                        }))
                      }
                      style={{
                        height: '100px',
                        padding: '10px',
                        resize: 'none',
                        width: '100%',
                      }}
                    />
                  </div>
                </Typography>
              </form>
            )}
          </Typography>
          <Typography sx={{ paddingTop: '15px' }} />
          {props?.from === 'admin' && <Divider />}
          <Typography sx={{ paddingTop: '15px' }} />
          <Typography className="model-btn" component={'div'}>
            <Button onClick={handleCloseAddLineItemModal} variant="text">
              Cancel
            </Button>
            {props?.from === 'admin' && (
              <Button variant="contained" onClick={handleSubmitAddLineItem}>
                Submit Line Item
              </Button>
            )}
          </Typography>
        </Box>
      </Modal>
    </Typography>
  );
};

export default PaySheet;
