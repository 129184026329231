import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  CircularProgress,
  ClickAwayListener,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import CollapsePng from 'assets/images/collapse.png';
import ExpandPng from 'assets/images/expand.png';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT, PAYCODETYPES, PAYSHEET } from 'utils/Constant';
import { postRequest } from 'utils/Handlers';

import useStyles from '../PrivateStyle';
import PayrollTable from './PayrollTable';

type AccordianProps = {
  index: number;
  item: any;
  expanded: string;
  tableData: any;
  userList: any;
  setExpanded: any;
  setEditObject: any;
  hiddenColumns: any;
};

const csvAdpData = [
  'Co Code',
  'Batch ID',
  'File #',
  'Reg Hours',
  'Reg Earnings',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Hours 3 code',
  'Hours 3 amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Hours 3 code',
  'Hours 3 amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  // last updated
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Hours 3 code',
  'Hours 3 Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
];

const csvData = [
  '',
  'Status',
  'Name',
  'Pay Code',
  'Applicant Status',
  'Notes',
  'Base Wage',
  'Base',
  'Co Code',
  'Batch ID',
  'File #',
  'Reg Hours',
  'Gross Earnings',
  'Reg Earnings',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Hours 3 code',
  'Hours 3 amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Hours 3 code',
  'Hours 3 amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Hours 3 code',
  'Hours 3 amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Earnings 3 Code',
  'Earnings 3 Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
  'Adjust DED Code',
  'Adjust DED Amount',
];

const PayPeriodAccordian = forwardRef((props: AccordianProps, ref: any) => {
  console.log('Child Rendered');
  // const { item, index, expanded, setExpanded, userList, setEditObject } = props;
  const { item, index, expanded, setExpanded, userList } = props;
  const classes = useStyles();
  const [expandView, setExpandView] = React.useState('');
  const [overAllData, setOverAllData] = React.useState([]);
  const [csvDataState, setCsvDataState] = React.useState<{
    currentReportData: any;
    adpReportData: any;
    originalReportData: any;
  }>({
    adpReportData: [],
    currentReportData: [],
    originalReportData: [],
  });
  const [param, setParam] = React.useState<any>({
    reload: true,
    skip: 0,
  });
  const [userFilter, setUserFilter] = React.useState<any>({
    payCode: '',
    userId: '',
  });
  const [summaryData, setSummaryData] = React.useState<{
    loader: boolean;
    data: any;
    total: number;
  }>({
    data: [],
    loader: true,
    total: 0,
  });

  const { companyStatus }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [openUser, setOpenUser] = React.useState<{
    open: boolean;
    placement: any;
    anchorEl: any;
  }>({
    anchorEl: null,
    open: false,
    placement: '',
  });

  const [openPayCode, setPayCode] = React.useState<{
    open: boolean;
    placement: any;
    anchorEl: any;
  }>({
    anchorEl: null,
    open: false,
    placement: '',
  });

  const [openPayRollSummary, setOpenPayRollSummary] = React.useState<{
    open: boolean;
    placement: any;
    anchorEl: any;
  }>({
    anchorEl: null,
    open: false,
    placement: '',
  });

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setOpenPayRollSummary({
        anchorEl: event.currentTarget,
        open: !openPayCode.open,
        placement: newPlacement,
      });
      if (!overAllData.length) {
        let url1 = PAYSHEET.Payroll + '/summary?payperiodId=' + expanded;
        if (companyStatus !== 'all') {
          url1 += '&organization=' + companyStatus;
        }
        getAllListData(url1).then((resp: any) => {
          setOverAllData(resp?.data);
        });
      }
    };

  const getPanelApi = React.useCallback(
    async (userFilterData: any, paramData: any, companyStatus: string) => {
      setSummaryData((pre: any) => {
        return {
          ...pre,
          data: [],
          loader: true,
        };
      });
      let url =
        PAYSHEET.Payroll +
        '/summary?payperiodId=' +
        item.id +
        '&skip=' +
        paramData.skip +
        '&take=50';
      if (companyStatus !== 'all') {
        url += '&organization=' + companyStatus;
      }
      if (userFilterData?.userId) {
        url += '&userId=' + userFilterData?.userId;
      }
      if (userFilterData?.payCode) {
        url += '&paycode=' + userFilterData?.payCode;
      }
      await getAllListData(url)
        .then((resp: any) => {
          setSummaryData((pre: any) => {
            return {
              ...pre,
              data: resp?.data,
              loader: false,
              total: resp?.total,
            };
          });
        })
        .catch((err: any) => {
          setSummaryData((pre: any) => {
            return {
              ...pre,
              loader: false,
            };
          });
        });
    },
    [item.id],
  );

  useImperativeHandle(ref, () => ({
    reloadData() {
      setParam({
        ...param,
        reload: !param.reload,
      });
    },
  }));

  useEffect(() => {
    if (param && Object.keys(param).length && expanded && companyStatus) {
      getPanelApi(userFilter, param, companyStatus);
    }
  }, [expanded, getPanelApi, param, userFilter, companyStatus]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    event.stopPropagation();
    setParam((pre: any) => {
      return {
        ...pre,
        skip: newPage * 50,
      };
    });
  };

  const paymentUpdate = (type: string) => {
    if (type === 'itech') {
      updateData(
        expanded,
        {},
        MASTER_ENDPOINT.PAYMENTPAYROLL + '/itech-payment',
        true,
      ).then(() => {});
    } else {
      updateData(
        expanded,
        {},
        MASTER_ENDPOINT.PAYMENTPAYROLL + '/smartworks-payment',
        true,
      ).then(() => {});
    }
  };

  const handleCreatePayPeriodSnapshot = async (payPeriodId: string) => {
    try {
      const response = await postRequest({
        data: {},
        url: `${MASTER_ENDPOINT.PayPeriodSnapshot}/${payPeriodId}`,
      });
      if (response.snapshotVersion) {
        toast.success(
          `Pay Period Snapshot Version ${
            response.snapshotVersion as string
          } created successfully`,
        );
      } else {
        toast.error('Failed to create Pay Period Snapshot');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOpenPayRollSummary((pre: any) => {
        return { ...pre, open: false };
      });
    }
  };

  return (
    <>
      <Accordion
        key={index}
        expanded={expanded === item.id}
        className={
          expanded === item.id && expanded === expandView
            ? 'fullViewAccordian'
            : expandView
            ? 'hideAll'
            : ''
        }>
        <AccordionSummary
          aria-controls="panel1a-content"
          onClick={() => setExpanded(item.id)}
          id="panel1a-header">
          <Typography
            sx={{ width: '45% !important' }}
            component={'div'}
            className="accordin-details">
            <Typography component={'p'}>
              {moment(item.startDate).format('MMM')}{' '}
              <strong>{moment(item.startDate).format('DD')}</strong> -{' '}
              {moment(item.endDate).format('MMM')}{' '}
              <strong>{moment(item.endDate).format('DD')}</strong>
              &nbsp;&nbsp;&nbsp;
              <strong>{moment(item.endDate).format('YYYY')}</strong>
            </Typography>
          </Typography>
          <Typography sx={{ width: '50% !important' }} component={'div'}>
            {expanded === item.id && (
              <Typography
                sx={{ display: 'flex' }}
                justifyContent={'end'}
                alignItems={'center'}>
                {(companyStatus === 'smartworks' ||
                  companyStatus === 'itech') && (
                  <Button
                    onClick={(event: any) => {
                      event.stopPropagation();
                      paymentUpdate(companyStatus);
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#BF0000',
                        color: '#FFFFFF',
                      },
                      backgroundColor: '#BF0000',
                      border: '1px solid #E0E0E0',
                      color: '#FFFFFF',
                      fontFamily: 'Red Hat Display Bold',
                      height: '40px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      textTransform: 'capitalize',
                    }}>
                    Sync payment
                  </Button>
                )}
                <TablePagination
                  className={classes.defaultpagination}
                  component="div"
                  count={summaryData.total}
                  page={parseInt(param.skip) / 50}
                  onPageChange={handleChangePage}
                  rowsPerPage={50}
                  rowsPerPageOptions={[]}
                />
              </Typography>
            )}
          </Typography>
          <Typography
            component={'p'}
            sx={{
              alignItems: 'flex-end !important',
              alignSelf: 'center !important',
              display: 'flex !important',
              justifyContent: 'flex-end !important',
              width: '8% !important',
            }}>
            {expanded === item.id && (
              <IconButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  setExpandView(expandView ? '' : expanded);
                }}
                className="dropdown-btn"
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}>
                <img
                  src={expandView ? CollapsePng : ExpandPng}
                  style={{ height: 20, width: 20 }}
                />
              </IconButton>
            )}
            <IconButton
              onClick={handleClick('bottom-end')}
              className="dropdown-btn"
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}>
              <MoreVertIcon />
            </IconButton>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px' }}>
          {expanded === item.id && (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component={'div'}
                  style={{ display: 'grid', width: '100%' }}
                  className="common-table">
                  {summaryData.loader ? (
                    <Typography
                      component={'div'}
                      sx={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                        paddingTop: '5px',
                        textAlign: 'center',
                      }}>
                      <CircularProgress sx={{ color: '#340000' }} />
                    </Typography>
                  ) : (
                    <PayrollTable
                      summaryTableData={summaryData.data.map((row, index) => {
                        return { ...row, summaryDataIndex: index };
                      })}
                      hiddenColumns={props.hiddenColumns}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      {openUser.open && (
        <ClickAwayListener
          onClickAway={() =>
            setOpenUser((pre: any) => {
              return { ...pre, open: !openUser.open };
            })
          }>
          <Popper
            className="popwidget1"
            open={openUser.open}
            anchorEl={openUser.anchorEl}
            placement={openUser.placement}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    <Autocomplete
                      selectOnFocus
                      clearOnBlur
                      freeSolo
                      // value={userId}
                      onChange={(e: any, reson: any) => {
                        if (reson) {
                          setUserFilter({
                            payCode: '',
                            userId: reson.id,
                          });
                          setOpenUser((pre: any) => {
                            return { ...pre, open: !openUser.open };
                          });
                          setParam({
                            reload: !param.reload,
                            skip: 0,
                          });
                        } else {
                          setUserFilter({
                            payCode: '',
                            userId: '',
                          });
                        }
                      }}
                      handleHomeEndKeys
                      id="combo-box-demo"
                      options={userList}
                      getOptionLabel={(option) =>
                        option.firstName + ' ' + option.lastName
                      }
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select" />
                      )}
                    />
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
      {openPayCode.open && (
        <ClickAwayListener
          onClickAway={() =>
            setPayCode((pre: any) => {
              return { ...pre, open: !openPayCode.open };
            })
          }>
          <Popper
            className="popwidget1"
            open={openPayCode.open}
            anchorEl={openPayCode.anchorEl}
            placement={openPayCode.placement}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    <Autocomplete
                      selectOnFocus
                      clearOnBlur
                      freeSolo
                      // value={userId}
                      onChange={(e: any, reson: any) => {
                        if (reson) {
                          setUserFilter({
                            payCode: reson.id,
                            userId: '',
                          });
                          setParam({
                            reload: !param.reload,
                            skip: 0,
                          });
                          setPayCode((pre: any) => {
                            return { ...pre, open: !openPayCode.open };
                          });
                        } else {
                          setUserFilter({
                            payCode: '',
                            userId: '',
                          });
                        }
                      }}
                      handleHomeEndKeys
                      id="combo-box-demo"
                      options={Object.values(PAYCODETYPES)}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select" />
                      )}
                    />
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
      {openPayRollSummary.open && (
        <ClickAwayListener
          onClickAway={() =>
            setOpenPayRollSummary((pre: any) => {
              return { ...pre, open: !openPayRollSummary.open };
            })
          }>
          <Popper
            className="popwidget"
            open={openPayRollSummary.open}
            anchorEl={openPayRollSummary.anchorEl}
            placement={openPayRollSummary.placement}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography sx={{ p: 2 }}>
                    <p style={{ margin: 0, padding: 10, paddingTop: 15 }}>
                      <div>
                        <span
                          onClick={() => {
                            handleCreatePayPeriodSnapshot(item.id);
                          }}
                          style={{
                            backgroundColor: 'transparent',
                            color: '#fff',
                            cursor: 'pointer',
                            fontFamily: 'Red Hat Display Bold',
                            fontSize: 15,
                            justifyContent: 'flex-start',
                            margin: 0,
                            padding: 0,
                            textDecoration: 'inherit',
                            textTransform: 'capitalize',
                          }}>
                          Create Pay Period Snapshot
                        </span>
                      </div>
                    </p>
                    <p style={{ margin: 0, padding: 10, paddingTop: 5 }}>
                      <CSVLink
                        style={{
                          backgroundColor: 'transparent',
                          color: '#fff',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: 15,
                          justifyContent: 'flex-start',
                          margin: 0,
                          padding: 0,
                          textDecoration: 'inherit',
                          textTransform: 'capitalize',
                        }}
                        filename={
                          companyStatus === 'itech'
                            ? 'EPIHVY01.csv'
                            : 'EPIHB501.csv'
                        }
                        data={csvDataState.currentReportData}
                        asyncOnClick={true}
                        onClick={async (event, done) => {
                          const currentCsvData = [
                            [...csvData, 'Adjusted Hour'],
                          ];
                          await overAllData.map((item: any, index: number) => {
                            currentCsvData.push([
                              index + 1,
                              item?.user_workAuth,
                              item?.user_lastName + ', ' + item?.user_firstName,
                              item?.user_jobWcCode,
                              item?.user_consultantStatus,
                              item?.summary_notes,
                              item?.summary_baseWage,
                              item?.summary_basePay,
                              item?.user_companyCode,
                              item?.summary_batchId,
                              item?.user_empCode,
                              item?.summary_regHours,
                              item?.summary_grossEarnings
                                ? parseFloat(item?.summary_grossEarnings)
                                : 0,
                              item?.summary_regEarnings,
                              item?.summary_currentEarnings3Code1,
                              item?.summary_currentEarnings3Amount1,
                              item?.summary_currentHours3Code1,
                              item?.summary_currentHours3Amount1,
                              item?.summary_currentEarnings3Code2,
                              item?.summary_currentEarnings3Amount2,
                              item?.summary_currentHours3Code2,
                              item?.summary_currentHours3Amount2,
                              item?.summary_currentEarnings3Code3,
                              item?.summary_currentEarnings3Amount3,
                              item?.summary_currentEarnings3Code4,
                              item?.summary_currentEarnings3Amount4,
                              item?.summary_currentEarnings3Code5,
                              item?.summary_currentEarnings3Amount5,
                              item?.summary_currentEarnings3Code6,
                              item?.summary_currentEarnings3Amount6,
                              item?.summary_currentHours3Code3,
                              item?.summary_currentHours3Amount3,
                              item?.summary_currentEarnings3Code7,
                              item?.summary_currentEarnings3Amount7,
                              item?.summary_currentEarnings3Code8,
                              item?.summary_currentEarnings3Amount8,
                              item?.summary_currentEarnings3Code9,
                              item?.summary_currentEarnings3Amount9,
                              item?.summary_currentAdjustDedCode1,
                              item?.summary_currentAdjustDedAmount1,
                              item?.summary_currentAdjustDedCode2,
                              item?.summary_currentAdjustDedAmount2,
                              item?.summary_currentAdjustDedCode3,
                              item?.summary_currentAdjustDedAmount3,
                              item?.summary_currentAdjustDedCode4,
                              item?.summary_currentAdjustDedAmount4,
                              item?.summary_currentAdjustDedCode5,
                              item?.summary_currentAdjustDedAmount5,
                              item?.summary_currentAdjustDedCode6,
                              item?.summary_currentAdjustDedAmount6,
                              item?.summary_currentAdjustDedCode7,
                              item?.summary_currentAdjustDedAmount7,
                              item?.summary_currentAdjustDedCode8,
                              item?.summary_currentAdjustDedAmount8,
                              item.summary_psAdjustedAmount,
                            ]);
                          });
                          setCsvDataState((pre: any) => {
                            return {
                              ...pre,
                              currentReportData: currentCsvData,
                            };
                          });
                          done(true);
                        }}>
                        Download Current Report
                      </CSVLink>
                    </p>
                    <p style={{ margin: 0, padding: 10, paddingTop: 5 }}>
                      <CSVLink
                        style={{
                          backgroundColor: 'transparent',
                          color: '#fff',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: 15,
                          justifyContent: 'flex-start',
                          margin: 0,
                          padding: 0,
                          textDecoration: 'inherit',
                          textTransform: 'capitalize',
                        }}
                        filename={
                          companyStatus === 'itech'
                            ? 'EPIHVY01.csv'
                            : 'EPIHB501.csv'
                        }
                        data={csvDataState.adpReportData}
                        asyncOnClick={true}
                        onClick={async (event, done) => {
                          const currentCsvData = [csvAdpData];
                          await overAllData.map((item: any, index: number) => {
                            currentCsvData.push([
                              item?.user_companyCode,
                              item?.summary_batchId,
                              item?.user_empCode,
                              item?.summary_regHours,
                              item?.user_jobWcCode === PAYCODETYPES.HOURLY.id
                                ? ''
                                : item?.summary_regEarnings,
                              item?.summary_currentEarnings3Code1,
                              item?.summary_currentEarnings3Amount1,
                              item?.summary_currentHours3Code1,
                              item?.summary_currentHours3Amount1,
                              item?.summary_currentEarnings3Code2,
                              item?.summary_currentEarnings3Amount2,
                              item?.summary_currentHours3Code2,
                              item?.summary_currentHours3Amount2,
                              item?.summary_currentEarnings3Code3,
                              item?.summary_currentEarnings3Amount3,
                              item?.summary_currentAdjustDedCode1,
                              item?.summary_currentAdjustDedAmount1,
                              item?.summary_currentAdjustDedCode2,
                              item?.summary_currentAdjustDedAmount2,
                              item?.summary_currentAdjustDedCode3,
                              item?.summary_currentAdjustDedAmount3,
                              item?.summary_currentAdjustDedCode4,
                              item?.summary_currentAdjustDedAmount4,
                              item?.summary_currentAdjustDedCode5,
                              item?.summary_currentAdjustDedAmount5,
                              // last update
                              item?.summary_currentEarnings3Code4,
                              item?.summary_currentEarnings3Amount4,
                              item?.summary_currentEarnings3Code5,
                              item?.summary_currentEarnings3Amount5,
                              item?.summary_currentEarnings3Code6,
                              item?.summary_currentEarnings3Amount6,
                              item?.summary_currentHours3Code3,
                              item?.summary_currentHours3Amount3,
                              item?.summary_currentEarnings3Code7,
                              item?.summary_currentEarnings3Amount7,
                              item?.summary_currentEarnings3Code8,
                              item?.summary_currentEarnings3Amount8,
                              item?.summary_currentEarnings3Code9,
                              item?.summary_currentEarnings3Amount9,
                            ]);
                          });
                          setCsvDataState((pre: any) => {
                            return {
                              ...pre,
                              adpReportData: currentCsvData,
                            };
                          });
                          done(true);
                        }}>
                        Generate ADP File
                      </CSVLink>
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 10,
                        paddingBottom: 15,
                        paddingTop: 5,
                      }}>
                      <CSVLink
                        style={{
                          backgroundColor: 'transparent',
                          color: '#fff',
                          fontFamily: 'Red Hat Display Bold',
                          fontSize: 15,
                          justifyContent: 'flex-start',
                          margin: 0,
                          padding: 0,
                          textDecoration: 'inherit',
                          textTransform: 'capitalize',
                        }}
                        filename={
                          companyStatus === 'itech'
                            ? 'EPIHVY01.csv'
                            : 'EPIHB501.csv'
                        }
                        data={csvDataState.originalReportData}
                        asyncOnClick={true}
                        onClick={async (event, done) => {
                          const currentCsvData = [csvData];
                          await overAllData.map((item: any, index: number) => {
                            currentCsvData.push([
                              index + 1,
                              item?.user_workAuth ? item?.user_workAuth : '',
                              item?.user_lastName + ', ' + item?.user_firstName,
                              item?.user_jobWcCode,
                              item?.user_consultantStatus,
                              item?.summary_notes,
                              item?.summary_baseWage,
                              item?.summary_basePay,
                              item?.user_companyCode,
                              item?.summary_batchId,
                              item?.user_empCode,
                              item?.summary_regHours,
                              item?.summary_grossEarnings
                                ? parseFloat(item?.summary_grossEarnings)
                                : 0,
                              item?.summary_regEarnings,
                              item?.summary_currentEarnings3Code1,
                              item?.summary_currentEarnings3Amount1,
                              item?.summary_currentHours3Code1,
                              item?.summary_currentHours3Amount1,
                              item?.summary_currentEarnings3Code2,
                              item?.summary_currentEarnings3Amount2,
                              item?.summary_currentHours3Code2,
                              item?.summary_currentHours3Amount2,
                              item?.summary_currentEarnings3Code3,
                              item?.summary_currentEarnings3Amount3,
                              item?.summary_currentEarnings3Code4,
                              item?.summary_currentEarnings3Amount4,
                              item?.summary_currentEarnings3Code5,
                              item?.summary_currentEarnings3Amount5,
                              item?.summary_currentEarnings3Code6,
                              item?.summary_currentEarnings3Amount6,
                              item?.summary_currentHours3Code3,
                              item?.summary_currentHours3Amount3,
                              item?.summary_currentEarnings3Code7,
                              item?.summary_currentEarnings3Amount7,
                              item?.summary_currentEarnings3Code8,
                              item?.summary_currentEarnings3Amount8,
                              item?.summary_currentEarnings3Code9,
                              item?.summary_currentEarnings3Amount9,
                              item?.summary_currentAdjustDedCode1,
                              item?.summary_currentAdjustDedAmount1,
                              item?.summary_currentAdjustDedCode2,
                              item?.summary_currentAdjustDedAmount2,
                              item?.summary_currentAdjustDedCode3,
                              item?.summary_currentAdjustDedAmount3,
                              item?.summary_currentAdjustDedCode4,
                              item?.summary_currentAdjustDedAmount4,
                              item?.summary_currentAdjustDedCode5,
                              item?.summary_currentAdjustDedAmount5,
                              item?.summary_currentAdjustDedCode6,
                              item?.summary_currentAdjustDedAmount6,
                              item?.summary_currentAdjustDedCode7,
                              item?.summary_currentAdjustDedAmount7,
                              item?.summary_currentAdjustDedCode8,
                              item?.summary_currentAdjustDedAmount8,
                            ]);
                          });
                          setCsvDataState((pre: any) => {
                            return {
                              ...pre,
                              originalReportData: currentCsvData,
                            };
                          });
                          done(true);
                        }}>
                        Download Original Report
                      </CSVLink>
                    </p>
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
});

export default React.memo(PayPeriodAccordian);
