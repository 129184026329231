import { Box, Button, Modal, Typography } from '@mui/material';
import { tableCols } from 'pages/private/PayrollSummary/PayrollTable';
import React, { useEffect, useState } from 'react';

export interface HideColumnsProps {
  setHiddenColumns: React.Dispatch<{ label: string; value: string }[]>;
}

export default function HideColumns({ setHiddenColumns }: HideColumnsProps) {
  const [showHideColumnsModal, setShowHideColumnsModal] = useState(false);
  const [hideableColumns, setHideableColumns] = useState({
    Columns: tableCols.slice(1).map((tableCol) => {
      return {
        checked: true,
        label: tableCol.headerName,
        value: tableCol.field,
      };
    }),
  });

  useEffect(() => {
    setHiddenColumns(
      Object.keys(hideableColumns)
        .map((key) => {
          return hideableColumns[key].filter((column) => !column.checked);
        })
        .flat(),
    );
  }, [hideableColumns, setHiddenColumns]);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setShowHideColumnsModal(true)}
        sx={{
          backgroundColor: '#BF0000',
          color: '#FFFFFF',
          fontFamily: 'Red Hat Display Bold',
          paddingLeft: '10px',
          paddingRight: '10px',
          position: 'absolute',
          right: '20px',
          textTransform: 'capitalize',
          top: '20px',
        }}>
        Hide Columns
      </Button>
      <Modal
        open={showHideColumnsModal}
        onClose={() => {
          setShowHideColumnsModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          alignItems: 'center',
          display: 'absolute',
          justifyContent: 'center',
          overflow: 'scroll',
        }}>
        <div className="modal-content">
          <Box
            className=""
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '10px',
              boxShadow: 24,
              margin: 'auto',
              p: 4,
              position: 'absolute' as const,
              width: '50%',
            }}>
            <Typography
              id="modal-modal-title"
              component="h2"
              style={{
                fontFamily: 'Red Hat Display Bold',
                fontSize: '20px',
                marginBottom: '20px',
                textAlign: 'center',
              }}>
              Active Payroll Summary Columns
            </Typography>
            <Typography
              className="confirm-btn model-btn"
              component={'div'}
              style={{
                display: 'flex',
                justifyContent: 'right',
                marginBottom: '20px',
              }}>
              <Button
                variant="outlined"
                onClick={() => setShowHideColumnsModal(false)}
                style={{
                  backgroundColor: '#BF0000',
                  color: '#FFFFFF',
                  fontFamily: 'Red Hat Display Bold',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  position: 'absolute',
                  right: '20px',
                  textTransform: 'capitalize',
                  top: '20px',
                }}>
                X
              </Button>
            </Typography>
            <Typography
              id="modal-modal-content"
              sx={{ display: 'flex', maxHeight: '50%' }}>
              {Object.keys(hideableColumns).map((key: any, index: number) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginRight: '20px',
                      width: '100%',
                    }}>
                    <Typography
                      component="h3"
                      style={{
                        fontFamily: 'Red Hat Display Bold',
                        fontSize: '16px',
                      }}>
                      {key}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '10px',
                      }}>
                      {hideableColumns[key].map((item: any, index: number) => {
                        return (
                          <div
                            key={index}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'left',
                              marginBottom: '10px',
                              marginRight: '10px',
                            }}>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              onChange={(e) => {
                                setHideableColumns({
                                  ...hideableColumns,
                                  [key]: hideableColumns[key].map((column) => {
                                    if (column.value === item.value) {
                                      return {
                                        ...column,
                                        checked: e.target.checked,
                                      };
                                    }
                                    return column;
                                  }),
                                });
                              }}
                              style={{
                                height: '20px',
                                marginRight: '5px',
                                width: '20px',
                              }}
                            />
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Typography>
          </Box>
        </div>
      </Modal>
    </>
  );
}
